import { useState, useEffect } from 'react'

const useBreakpoints = () => {
  const [breakpoint, setBreakpoint] = useState<breakpoint>()

  useEffect(() => {
    const getBreakpoint = () => {
      if (matchMedia('(max-width: 767px)').matches) setBreakpoint('mobile')
    }

    getBreakpoint()

    window.addEventListener('resize', getBreakpoint)
    return window.removeEventListener('resize', getBreakpoint)
  }, [])

  return breakpoint
}

export default useBreakpoints
