// extracted by mini-css-extract-plugin
export var active = "Home-module--active--FlcF-";
export var banner = "Home-module--banner--aENQO";
export var bannerBusinessContact = "Home-module--banner-business-contact--97XcZ";
export var brandsList = "Home-module--brands-list--L7i8B";
export var contatos = "Home-module--contatos--exiTa";
export var content1364 = "Home-module--content-1364--xUXDe";
export var content812 = "Home-module--content-812--1vfA0";
export var content897 = "Home-module--content-897--73iag";
export var empresas = "Home-module--empresas--qKDeQ";
export var equalHeightGridTop = "Home-module--equal-height-grid-top--Ctm8A";
export var faleConosco = "Home-module--fale-conosco--0SP0X";
export var grid100 = "Home-module--grid-100--E+Nm3";
export var grid50 = "Home-module--grid-50--XaLFD";
export var sobre = "Home-module--sobre--TM9Ij";
export var stations = "Home-module--stations--vsUhc";
export var timeline = "Home-module--timeline--1PqpM";
export var workUs = "Home-module--work-us--lPifx";
export var yellowBox = "Home-module--yellow-box--Xo8QA";